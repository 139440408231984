import axios from "axios";
import store from "../store/index"
import Message from "element-ui/packages/message/src/main";
import {local} from "@/utils/storage";
import router from "@/router";
import apiLogin from "@/api/login";

let isRefreshing = false; // 是否正在刷新的标记
axios.defaults.timeout = 1000000
axios.interceptors.request.use(config => {
    if (local.get("Token")) {
        console.log(config)
        if (!config.url.includes('RefreshToken')) {
            config.headers["Authorization"] = 'Bearer ' + local.get("Token");
        }
        config.headers["currentOrganizationId"] = local.get("currentOrganizationId")
        store.commit('showLoading')
    }
    // Authorization Bearer
    return config;
}, error => {
    store.commit('hideLoading')
    return Promise.reject(error);
});
axios.interceptors.response.use(response => {
    store.commit('hideLoading')

    return response;
}, error => {
    if (error.response) {
        switch (error.response.status) {
            case 302:
                store.commit('hideLoading')
                local.clear()
                router.push('/')
                break;
            case 401:
                store.commit('hideLoading')
                RefreshFn(error.response)
                break;
            case 500:
                store.commit('hideLoading')
                Message.error("服务器出现错误")
                break;
            case 404:
                store.commit('hideLoading')
                Message.error("404接口调用错误")
                break;
        }
        return Promise.reject(error.response.data);
    }
    if (error && error.stack.indexOf('timeout') > -1) {
        store.commit('hideLoading')
        Message.error('现在网络不稳定,请稍后在试!')
    }
});

function RefreshFn(response) {
    let access_token = local.get("Token");
    let refresh_token = local.get("refresh_token");
    if (access_token === 'undefined' || !refresh_token === 'undefined') {
        alert("401")
        return Promise.reject(response.data);
    }
    access_token = 'Bearer ' + access_token
    refresh_token = 'Bearer ' + refresh_token
    if (!isRefreshing) {
        isRefreshing = true;
        return apiLogin.RefreshToken({access_token: access_token, refresh_token: refresh_token}).then(res => {
            if (res.data.Status === 1) {
                local.set("Token", res.data.Data.access_token.split(" ")[1])
                local.set("refresh_token", res.data.Data.refresh_token.split(" ")[1])
                window.location.reload();
            } else if (res.code === -1) {
                alert("登录信息失效，请重新登录");
            }
        }).finally(() => {
            isRefreshing = false;
        })
    }

}

export default axios;




