import axios from "./Interceptor.js"
let address = window.global_config.BASE_URL2

let derive = {
    ProjectListData: (info) => {
        return axios({
            method: "post",
            url: `${address}Project/GetTree`,
            data: info,
        })
    },
    CRFSurfaceData: (info) => {
        return axios({
            method: "post",
            url: `${address}QuestionnaireCenter/List`,
            data: info,
        })
    },
    QuestionnaireTokenData: () => {//获取问卷系统token
        return axios({
            method: "post",
            url: `${address}QuestionnaireCenter/GetConfig`,
        })
    },
    FollowUpRecordsData: (info) => {//项目患者随访记录
        return axios({
            method: "post",
            url: `${address}PatientCenter/FollowUp/GetPageList`,
            data: info,
        })
    },
    SeeFollowUpRecordsData: () => {//查看项目患者随访记录
        return axios({
            method: "post",
            url: `${address}PatientCenter/Questionnaire/Get`,
        })
    },
    EditFollowUpRecordsData: () => {//编辑项目患者随访记录
        return axios({
            method: "post",
            url: `${address}PatientCenter/Questionnaire/Edit`,
        })
    },
    DeleteFollowUpRecordsData: (info) => {//删除项目患者随访记录
        return axios({
            method: "post",
            url: `${address}PatientCenter/Questionnaire/Delete`,
            data: info,
        })
    },
    CollectedExportData: (info) => {//已收集问卷导出
        return axios({
            method: "post",
            url: `${address}PatientCenter/Questionnaire/Collected/Export`,
            data: info,
            headers: { Accept: 'application/vnd.ms-excel' },
            responseType: 'blob',
        })
    },
    PatientExportData: (info) => {//患者查询导出
        return axios({
            method: "post",
            url: `${address}PatientCenter/Export`,
            data: info,
            headers: { Accept: 'application/vnd.ms-excel' },
            responseType: 'blob',
        })
    },
    PatientGetIdListData: (info) => {//[患者id数组]
        return axios({
            method: "post",
            url: `${address}PatientCenter/GetIdList`,
            data: info,
        })
    },
    PatientCenterGet: (id) => {//[患者id数组]
        return axios({
            method: "get",
            url: `${address}PatientCenter/Get?id=${id}`,
        })
    },
    setConfig: (data) => {//[患者id数组]
        return axios({
            method: "post",
            url: `${address}QuestionnaireCenter/SetUp`,
            data
        })
    },
    tamplateList: (data) => {//[患者id数组]
        return axios({
            method: "post",
            url: `${address}QuestionnaireCenter/GetTemplatePageList`,
            data
        })
    },
    delTemplate: (data) => {//[患者id数组]
        return axios({
            method: "post",
            url: `${address}QuestionnaireCenter/TemplateDelete`,
            data
        })
    },
    toTemplate: (data) => {//[患者id数组]
        return axios({
            method: "post",
            url: `${address}QuestionnaireCenter/Template`,
            data
        })
    },
    toQuestionnaire: (data) => {//[患者id数组]
        return axios({
            method: "post",
            url: `${address}QuestionnaireCenter/TemplateToQuestionnaire`,
            data
        })
    },
    Prepare: (data) => {//[患者id数组]
        return axios({
            method: "post",
            url: `${address}CallBack/Prepare`,
            data
        })
    },
}

export default derive