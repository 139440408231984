<template>
  <div style="display: grid;">
    <div class="report_head">
      <span>项目:</span>
<!--      <el-select v-model="value" placeholder="请选择" @change="change">-->
<!--        <el-option v-for="(item, index) in Project" :key="index" :label="item.Name" :value="item.Id">-->
<!--        </el-option>-->
<!--      </el-select>-->
      <Cascader :value.sync="value" :options="Project" @MyChange="change" :multiple="false"></Cascader>
      <!-- <el-button type="primary" icon="el-icon-search" style="margin-left:20px;">查询</el-button> -->
      <el-button type="primary" icon="el-icon-download" style="margin-left:20px;" @click="WorkPartReport">导出
      </el-button>
    </div>
    <div class="report-page">
      <!-- <div >1111111</div> -->
      <div style=";aspect-ratio: 980/760;" class="tooData">
        <div class="report-left-top">
          <div class="report-title">
            <span></span>
            <span>工作汇报</span>
          </div>
          <div style="display: flex" class="domData">
            <div @click="GotoProjectListDetail" style="cursor: pointer;" class="report-content project-num">
              <span>项目总数（个）</span>
              <div class="report-flex">
                <p>{{ TotalObj.ProjectTotal }}</p>
                <img src="../../assets/image/taskmanagement/projecttype1.png" alt="" style="width: 27px;height: 27px">
              </div>
            </div>
            <div @click="GotoPartcipantDetail" style="cursor: pointer;" class="report-content doctor-num">
              <span>参研医生总数（人）</span>
              <div class="report-flex">
                <p>{{ TotalObj.UserTotal }}</p>
                <img src="../../assets/image/taskmanagement/projecttype1.png" alt="" style="width: 27px;height: 27px">
              </div>
            </div>
            <div @click="GotoPatientTotal" style="cursor: pointer;" class="report-content patient-num">
              <span>患者总数（人）</span>
              <div class="report-flex">
                <p>{{ TotalObj.PatientTotal }}</p>
                <img src="../../assets/image/taskmanagement/projecttype1.png" alt="" style="width: 27px;height: 27px">
              </div>
            </div>
          </div>
        </div>
        <div class="report-left-bottom">
          <div class="report-title">
            <span></span>
            <span>工作汇报</span>
          </div>
          <div style="height: 440px">
            <el-table :data="ReportData" max-height="440px" :header-cell-style="{
              color: '#fff',
              backgroundColor: '#3388ff',
              'text-align': 'center'
            }" style="width: 100%">
              <el-table-column prop="ProjectName" label="项目名称" align="center"></el-table-column>
              <el-table-column prop="UserName" label="姓名" align="center" width="150"></el-table-column>
              <el-table-column prop="UnitName" label="单位" align="center" width="180"></el-table-column>
              <el-table-column prop="PatientTotal" label="患者总数(人)" align="center" width="180">
                <template slot-scope="scope">
                  <div @click="GotoPatentListDetail(scope.row)" style="cursor:pointer;">{{
                      scope.row.PatientTotal
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="PatientCallTotal" label="已随访总数(人/次)" align="center" width="180">
                <template slot-scope="scope">
                  <div @click="GotoSuiFangListDetail(scope.row)" style="cursor:pointer;">{{
                      scope.row.PatientCallTotal
                    }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- <Pagination
              :total="total"
              :page.sync="listQuery.page"
	            :limit.sync="listQuery.rows"
               @pagination="GetList"
           ></Pagination> -->
        </div>
      </div>
      <div style="aspect-ratio: 980/760;" class="bottomData">
        <div class="report-right-top">
          <div class="report-title">
            <span></span>
            <span>数据收集完成率</span>
          </div>
          <div>
            <div v-if="UnitfollowUp.length == 0"
                 style="text-align: center;color: #999;height: 240px;line-height: 240px;">
              暂无数据
            </div>
            <div v-else>
              <div id="staisticsB" style="height: 270px"></div>
            </div>
          </div>
        </div>
        <div style="display: flex;width: 100%;">
          <div class="report-right-bottom report-hospital" style="margin-right: 1%">
            <div class="report-title">
              <span></span>
              <span>各单位收集问卷数</span>
            </div>
            <div>
              <div class="hospital-questionnaire-num">
                <span>单位</span>
                <span>患者总数(人)</span>
                <span>已收集问卷</span>
              </div>
              <div v-if="UnitQuestionnaire.length == 0" style="text-align: center;color: #999;">暂无数据</div>
              <ul v-else v-infinite-scroll="load2" style="height:260px;overflow: hidden;overflow:auto;">
                <li v-for="(item, index) in UnitQuestionnaire" :key="index">
                  <div style="display: flex">
                    <div style="width: 125px;text-align: center;" class="questda">
                      <el-tooltip class="item" effect="light" :content="item.UnitName" placement="top">
                        <span
                          style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display:inline-block;width: 100%;">{{
                            item.UnitName
                          }}</span>
                      </el-tooltip>
                    </div>
                    <div style="width: 125px;text-align: center;cursor: pointer" @click="GotoPatientTotalDetail(item)">
                      {{
                        item.PatientTotal
                      }}
                    </div>
                    <div style="width: 125px;text-align: center;cursor: pointer" @click="GotoCollDetail(item)">
                      <span>{{ item.QuestionnaireTotal }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="report-right-bottom report-follow">
            <div class="report-title">
              <span></span>
              <span>各单位随访完成情况</span>
            </div>
            <div>
              <div v-if="UnitFollowUp.length == 0" style="text-align: center;color: #999;">暂无数据</div>
              <ul v-else v-infinite-scroll="load2" style="height:300px;overflow: hidden;overflow:auto;">
                <li v-for="(item, index) in UnitFollowUp" :key="index">
                  <span>{{ item.UnitName }}</span>
                  <div>
                    <span>患者总数</span>
                    <span style="color:#FF9900;">{{ item.PatientTotal }}</span>
                    <span>人</span>
                    <span style="margin-left: 20px;">已随访</span>
                    <span style="color: #FA5A8E">{{ item.PatientCallTotal }}</span>
                    <span>人</span>
                    <span style="margin-left: 20px;">完成率</span>
                    <span style="color:#3388ff;">{{ item.percentage }}%</span>
                  </div>
                  <!-- percentage值超过100控制台会报错  -->
                  <el-progress :text-inside="false" :stroke-width="8" :percentage="item.percentage" style="width:221px;"
                               color="#5F7FFE"></el-progress>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import WorkReport from "../../api/WorkReport"
import derive from "../../api/derive"
import Cascader from "@/components/Cascader.vue";

export default {
  name: "report",
  data() {
    return {
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      value: ["ALL"],
      TotalObj: {},
      UnitfollowUp: [],
      ReportData: [],
      UnitQuestionnaire: [],
      UnitFollowUp: [],
      Project: [{Name: "全部", Id: "ALL", ParentId: null}]
      // total:0,
      // listQuery: {
      //   Key: "",
      //   page: 1,
      //   rows: 5,
      // },
    }
  },
  components: {
    Cascader
    // Pagination,
  },
  created() {
    this.GetWorkReport(['ALL'])
    this.ProjectList()
  },
  mounted() {
    window.addEventListener('orientationchange', function () {
      window.location.reload();
    })
  },
  methods: {
    //点击工作汇报患者
    GotoPatentListDetail(row) {
      this.$router.push({path: "/detail", query: {Type: "02", ProjectId: row.Id, UserId: row.UserId}});
    },
    //点击工作汇报已随访
    GotoSuiFangListDetail(row) {
      this.$router.push({path: "/detail", query: {Type: "03", ProjectId: row.Id, UserId: row.UserId}});
    },
    //点击项目
    GotoProjectListDetail() {
      this.$router.push({path: "/projectDetail", query: {Type: "00", ProjectId: this.value}});
    },
    //点击参研医生
    GotoPartcipantDetail() {
      this.$router.push({path: "/participants", query: {Type: "01", ProjectId: this.value}});
    },
    //点击患者总数
    GotoPatientTotal() {
      this.$router.push({path: "/detail", query: {Type: "02", ProjectId: this.value}});
    },
    //点击各单位收集情况 患者总数
    GotoPatientTotalDetail(item) {
      this.$router.push({path: "/detail", query: {Type: "02", ProjectId: item.Id, UnitId: item.ProjectUnitId}});
    },
    //点击各单位收集情况 已收集问卷
    GotoCollDetail(item) {
      this.$router.push({path: "/collDetail", query: {Type: "03", ProjectId: item.Id, UnitId: item.ProjectUnitId}});
    },
    downloadAudioStream(data, name) {
      // ArrayBuffer 转为 Blob
      const blob = new Blob([data], {type: 'application/vnd.ms-excel'})
      const objectUrl = URL.createObjectURL(blob)
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.style.display = 'none'
      a.setAttribute('href', objectUrl)
      a.setAttribute('target', '_blank')
      a.setAttribute('download', name + '.' + 'xls')
      a.click()
      URL.revokeObjectURL(objectUrl)
      document.body.removeChild(a)
    },
    WorkPartReport() {
      let paramenter = {
        ProjectId: this.value
      }
      WorkReport.WorkPartReportData(paramenter).then(res => {
        this.downloadAudioStream(res.data, '数据导出')
        console.log(res);
      })
    },
    change(e) {
      this.GetWorkReport(e)
    },
    //项目
    async ProjectList() {
      derive.ProjectListData().then(res => {
        if (res.data.Status == 1) {
          if (res.data.Data.length != 0) {
            this.Project.push(...res.data.Data)
          }
        } else {
          alert(res.data.Message)
        }
        console.log(res);
      })
    },
    // 工作汇报列表接口
    async GetWorkReport(value) {
      let Parameter = {
        ProjectId: value.slice(-1)
      }
      WorkReport.WorkPart1ReportData(Parameter).then(res => {
        if (res.data.Status == 1) {
          this.TotalObj = res.data.Data
        } else {
          alert(res.data.Message)
        }
        console.log(res, "工作汇报列表接口1");
      })
      WorkReport.WorkPart2ReportData(Parameter).then(res => {
        console.log(res, 'resrt')
        if (res.data.Status == 1) {
          this.UnitfollowUp = res.data.Data.map(item => {
            return {
              PatientTotal: item.PatientTotal,
              name: item.UnitName,
              ConnectedTotal: item.ConnectedTotal
            }
          })
        } else {
          alert(res.data.Message)
        }
        console.log(res, "工作汇报列表接口2");
        this.$nextTick(function () {
          this.statisticListB()
        })
      })
      WorkReport.WorkPart3ReportData(Parameter).then(res => {
        if (res.data.Status == 1) {
          this.ReportData = res.data.Data
        } else {
          alert(res.data.Message)
        }
        console.log(res, "工作汇报列表接口3");
      })
      WorkReport.WorkPart4ReportData(Parameter).then(res => {
        if (res.data.Status == 1) {
          this.UnitQuestionnaire = res.data.Data
        } else {
          alert(res.data.Message)
        }
        console.log(res, "工作汇报列表接口4");
      })
      WorkReport.WorkPart5ReportData(Parameter).then(res => {
        if (res.data.Status == 1) {
          this.UnitFollowUp = res.data.Data.map(item => {
            if (item.PatientCallTotal == 0 && item.PatientTotal == 0) {
              item["percentage"] = 0
            } else {
              let p = item.PatientCallTotal / item.PatientTotal
              item["percentage"] = this.NumFilter(p) * 100
            }
            return item
          })
          console.log(this.UnitFollowUp, "%%%");
        } else {
          alert(res.data.Message)
        }
        console.log(res, "工作汇报列表接口5");
      })
    },
    NumFilter(value) {
      // 截取当前数据到小数点后两位
      let realVal = Number(parseFloat(value).toFixed(2))
      return realVal
    },
    load2() {

    },
    // statisticListB1() {
    //   var chartDom = document.getElementById('staisticsB');
    //   var myChart = echarts.init(chartDom);
    //   var data =this.UnitfollowUp;

    //   // var data = [{name:'张三您好',value:100},{name:'张三您',value:200},{name:'张三好',value:300},{name:'张三您好',value:400}];
    //   for(let i =0;i<data.length;i++){
    //     for(let j =i+1;j<data.length; j++){
    //       if(data[i].name.trim() == data[j].name.trim()){
    //         data[i].name = data[i].name + ' ';
    //       }else{
    //         data[i].name = data[i].name + ' ';
    //         data[j].name = data[j].name + ' ';
    //       }
    //     }
    //   };
    //   console.log(data,'datares')
    //   var option;
    //   option = {
    //     title: {
    //       text: '各单位',
    //       subtext: "接通率占比",
    //       textStyle: {
    //         fontSize: 13,
    //         fontWeight: 400,
    //         color: "#333333",
    //         lineHeight: 20,
    //       },
    //       subtextStyle: {
    //         fontSize: 13,
    //         fontWeight: 400,
    //         color: "#333",
    //       },
    //       textAlign: "center",
    //       left: "21%",
    //       top: "38%",
    //     },
    //     tooltip: {
    //       trigger: 'item',
    //       formatter:function(val){
    //         return val.seriesName +' '+ val.data.name.trim()+ ': '+ val.data.value +'('+ val.percent +'%)';
    //       }
    //     },
    //     legend: {
    //       orient: 'vertical',
    //       icon: 'circle',
    //       left: 'right',
    //       top: '20%',
    //       left: '60%',
    //       itemGap: 12,
    //       // type: 'scroll',
    //       data,
    //       tooltip: {
    //         show: true,
    //         formatter:function(val){
    //           return val.name.trim()
    //         }
    //       },
    //       formatter: function(name) {
    //         let target;
    //           for (var i = 0; i < data.length; i++) {
    //             if(data[i].name == name){
    //               target=data[i].value
    //             }
    //             if(name.length > 24){
    //               name =  name.slice(0,24) + '...';
    //             }
    //           };
    //           return name.trim() +'\n'+'随访完成患者总数'+ target + '人';
    //         },
    //     },
    //     // color: [ "#319efd", "#3becf2", "#ff718e", "#ffdb00","#666",],
    //     series: [
    //       { name: '随访总数', type: 'pie', radius: ['40%', '55%'], avoidLabelOverlap: false,
    //         center: [180, '47%'],
    //         avoidLabelOverlap: true,
    //         itemStyle: {
    //           borderRadius: 10,
    //           // borderColor: '#fff',
    //           borderWidth: 2,
    //           normal: {
    //             label: {
    //               formatter: '{d}%'
    //             }
    //           }
    //         },
    //         label: {
    //           align: 'left',
    //           normal: {
    //             show: true,
    //             textStyle: {
    //               fontSize: 14,
    //               fontWeight: 400,
    //               color: "#333",
    //             }
    //           }
    //         },
    //         labelLine: {
    //           normal: {
    //             length: 15,
    //             length2: 30,
    //             lineStyle: {
    //               color:'#707070'
    //             }
    //           }
    //         },
    //         data: data,
    //       }
    //     ]
    //   };
    //   option && myChart.setOption(option);
    // },
    statisticListB() {
      var chartDom = document.getElementById('staisticsB');
      var myChart = echarts.init(chartDom);
      var data = this.UnitfollowUp;
      let dataX = [];
      let dataY = [];
      data.forEach((item, index) => {
        dataX.push(item.name);
        if (item.ConnectedTotal == 0) {
          dataY.push(0);
        } else {
          dataY.push((item.ConnectedTotal / item.PatientTotal * 100).toFixed(0));
        }
      });
      var option;
      option = {
        yAxis: {
          type: 'category',
          data: dataX,
          axisLabel: {
            interval: 0,
            // rotate:-10
            // formatter:function(value,index){
            //   if (index % 2 != 0) {
            //     return '\n\n' + value;
            //   }
            //   else {
            //     return value;
            //   }
            // }
            formatter: function (value) {
              if (value.length > 12) {
                return value.substring(0, 12) + '...';
              } else {
                return value;
              }
            }
          },
          axisTick: {
            show: true
          }
        },
        xAxis: {
          min: 0,
          type: 'value',
          axisLabel: {
            show: true,
            interval: 0,
            color: '#666666',
            formatter: '{value}%',
          },
          axisTick: {
            show: false
          }
        },
        tooltip: {
          formatter: function (val, index) {
            let NewIndex = index.substr(-1);
            return val.name + '</br>' +
              '患者总数：' + data[NewIndex].PatientTotal + '</br>' +
              '随访总数：' + data[NewIndex].ConnectedTotal + '</br>' +
              '随访患者完成率：' + val.value + '%';
          }
        },

        grid: {
          show: false,
          top: '0',
          left: '22%'
        },
        series: [{
          data: dataY,
          type: 'bar',
          barWidth: 20,
          showBackground: true,
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)'
          },
          itemStyle: {
            normal: {
              label: {
                formatter: function (value) {
                  return value.value + '%'
                },
                show: true,
                position: "right",
                textStyle: {
                  fontSize: "16",
                  color: "#666666"
                }
              },
              color: 'rgb(57, 162, 255)'
            },
          }
        }
        ]
      };
      option && myChart.setOption(option);
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/mixin.scss";

.report_head {
  width: 100%;
  padding: 20px 20px 10px 30px;
  box-sizing: border-box;
}

.report-page {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 1%;
  height: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 0px 0 30px 30px;
  overflow: hidden;

  .report-title {
    display: flex;
    margin-bottom: 10px;

    span:nth-child(1) {
      width: 3px;
      height: 12px;
      background: #3388ff;
      border-radius: 164px;
      margin-top: 5px;
      margin-right: 5px;
    }

    span:nth-child(2) {
      @include add-size($font_size_16);
      font-weight: 400;
      color: #333;
    }
  }

  .report-left-top {
    width: 100%;
    min-height: 220px;
    aspect-ratio: 970/220;
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 20px;
    box-sizing: border-box;
    padding: 15px;

    .project-num {
      background: linear-gradient(to right, #7E5CFF 0%, #9B93FF 100%);
    }

    .doctor-num {
      background: linear-gradient(to right, #59CAFF 0%, #2F94FF 100%);
    }

    .patient-num {
      background: linear-gradient(to right, #00CFE6 0%, #17EBD5 100%);
    }

    .report-content {
      width: 290px;
      height: 130px;
      box-sizing: border-box;
      color: #ffffff;
      border-radius: 10px;
      margin-right: 10px;
      margin-left: 10px;
      padding: 30px 20px 0 20px;

      .report-flex {
        display: flex;
        justify-content: space-between;

        img {
          margin-top: 40px;
        }
      }

      span:nth-child(1) {
        @include add-size5($font_size_14);
        font-weight: 400;
      }

      p {
        @include add-size3($font_size_34);
        font-weight: 400;
        margin-top: 10px;
      }
    }
  }

  .report-left-bottom {
    min-height: 510px;
    width: 100%;
    aspect-ratio: 970/510;
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 15px;
  }

  .report-right-top {
    // min-height: 360px;
    width: 97%;
    aspect-ratio: 845/315;
    background: #ffffff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    margin-bottom: 20px;
    box-sizing: border-box;
    padding: 15px;

    .staistics-right {
      font-weight: 400;
      @include add-size5($font_size_14);
      color: #585858;
    }
  }

  .report-right-bottom {
    min-height: 400px;
    background-color: red;
    width: 48%;
    aspect-ratio: 412/370;
    background: #fff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 15px;

    .hospital-questionnaire-num {
      line-height: 40px;
      background: #F0F7FD;
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      span {
        display: inline-block;
        text-align: center;
        color: #3388FF;
        font-size: 0.8rem;
      }
    }
  }

  .report-hospital {
    ul {
      li {
        // width: 383px;
        height: 40px;
        @include add-size($font_size_16);
        color: #585858;
        font-weight: 400;
        box-sizing: border-box;
        padding: 10px;
        border-bottom: 1px solid #E5E5E5;

        span {
          //   width: 250px;
          //   text-align: center;
          @include add-size($font_size_16);
          //   color: #585858;
        }

        // span:nth-child(2) {
        //   width: 133px;
        //   text-align: center;
        // }
      }
    }
  }

  .report-follow {
    ul {
      li {
        box-sizing: border-box;
        padding: 10px 0 8px 0;
        border-bottom: 1px solid #E5E5E5;

        span {
          font-weight: 400;
          @include add-size($font_size_16);
          color: #585858;
        }
      }
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {

  .report-page {
    min-width: 0 !important;
    padding: 0 !important;
    flex-direction: column;

    .questda {
      width: 80px !important;
    }

    .report-hospital {
      margin-left: 15px;
    }

    // div:nth-child(1){
    //  width: 100% !important;
    // }

    .report-left-top {
      min-width: 0 !important;
      min-height: 0 !important;
      aspect-ratio: 0 !important;

      .report-content {
        width: none !important;
        height: inherit !important;
        flex: 1;
        padding: 30px 20px 10px 20px !important;

        .report-flex {
          align-items: center;

          img {
            margin-top: 20px !important;
          }
        }
      }
    }

    .report-left-bottom {
      min-width: 0 !important;
      min-height: 0 !important;
      aspect-ratio: 0 !important;
      overflow-x: scroll;
    }

    .bottomData {
      width: 100% !important;
      margin-top: 20px;
      padding-bottom: 40px;

      .report-right-top {
        min-width: 0 !important;

        #staisticsB {
          width: 100% !important;

          div:nth-child(1) {
            width: 100% !important;
          }
        }
      }

      .report-right-bottom {
        width: 48% !important;
        min-width: 0 !important;
        min-height: 0 !important;
        margin-right: 5px !important;

        .hospital-questionnaire-num {
          width: 100% !important;

          span {
            width: 33% !important;
          }
        }
      }
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape) {
  .report-page {
    min-width: 0 !important;

    .questda {
      width: 80px !important;
    }

    .tooData {
      margin-right: 10px !important;
      aspect-ratio: 0 !important;
    }

    .report-left-top {
      min-width: 0 !important;

      .report-content {
        // width: inherit;
        flex: 1;
        padding: 30px 20px 0 15px !important;
      }

      .report-flex {
        align-content: center;

      }

      .domData {
        justify-content: space-between;

        .report-content {
          flex: 1 !important;
        }

        .doctor-num {
          padding: 10px 10px 0 10px !important;
        }
      }
    }

    .report-left-bottom {
      min-width: 0 !important;
    }

    .bottomData {

      #staisticsB {
        width: 100% !important;
        height: 300px !important;

        div:nth-child(1) {
          width: 100% !important;
        }
      }

      .report-right-top {
        min-width: 0 !important;

      }

      .report-right-bottom {
        min-width: 0 !important;

        .hospital-questionnaire-num {
          width: 100%;

          span {
            width: 33% !important;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; // 默认不换行；
          }
        }
      }
    }
  }
}</style>
