// 工作汇报api
import axios from "./Interceptor.js"
let address =window.global_config.BASE_URL2
let WorkReport = {
    WorkPart1ReportData: (info) => {
        return axios({
            method: "post", 
            url: `${address}Report/WorkPart1`,
            data:info
        })
    },
    WorkPart2ReportData: (info) => {
        return axios({
            method: "post", 
            url: `${address}Report/WorkPart2`,
            data:info
        })
    },
    WorkPart3ReportData: (info) => {
        return axios({
            method: "post", 
            url: `${address}Report/WorkPart3`,
            data:info
        })
    },
    WorkPart4ReportData: (info) => {
        return axios({
            method: "post", 
            url: `${address}Report/WorkPart4`,
            data:info
        })
    },
    WorkPart5ReportData: (info) => {
        return axios({
            method: "post", 
            url: `${address}Report/WorkPart5`,
            data:info
        })
    },
    WorkPartReportData: (info) => {//导出
        return axios({
            method: "post", 
            url: `${address}Report/WorkExport`,
            data:info,
            headers: { Accept: 'application/vnd.ms-excel' }, 
            responseType:'blob',
        })
    },
    //获取参研人员下的所有患者信息
    GetPatientList: (info) => {
        return axios({
            method:"post",
            url:`${address}Report/DetailPart `,
            data:info
        })
    },
}
export default WorkReport